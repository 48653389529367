import { XHR } from '@/helpers/xhr';
import { parseJson } from '@/helpers/json';
import { appSettings } from '@/AppSettings';

export default class User {
  constructor() {
    this.username = '';
    this.roles = ['ROLE_AUTHOR'];
    this.isConstructor = false;
    this.isLoaded = false;
  }

  async loadInfo() {
    let userInfo = await XHR.get(appSettings.get('userInfoUrl'), { absoluteUrl: true });
    userInfo = parseJson(userInfo);

    this.username = userInfo.username;
    this.roles = userInfo.roles.concat('ROLE_AUTHOR');
    this.isConstructor = this.roles.includes('ROLE_CONSTRUCTOR');
    this.isLoaded = true;
  }
}
