<template>
  <div
    class="uploader"
    :class="{ empty: isEmpty }"
    :style="wrapperStyle"
  >
    <a-upload
      listType="picture-card"
      :accept="accept"
      :beforeUpload="beforeUpload"
      :fileList="fileList"
      :showUploadList="false"
      @change="handleChange"
    >
      <div
        v-if="!fileList.length && !disabled && !readOnly"
        :style="uploaderStyle"
        class="uploader__load"
      >
        <div>
          <a-icon type="plus" />
          <div class="ant-upload-text">{{ $t('base.placeholder.upload') }}</div>
        </div>
      </div>

      <div
        v-else-if="fileList.length"
        class="uploader-image"
        :class="{ disabled: disabled || readOnly }"
        @click="imageClick"
      >
        <img
          :src="fileList[0].url"
          :width="width"
          :height="height"
          alt=""
        />
        <div class="uploader-image__overlay">
          <a-icon
            class="uploader-image__control"
            type="eye"
            @click.stop="showPreview"
          />
          <a-icon
            class="uploader-image__control"
            type="delete"
            @click.stop="remove"
          />
        </div>
      </div>
    </a-upload>
    <a-modal
      :visible="!!activePreview"
      :footer="null"
      @cancel="closePreview"
    >
      <img
        style="width: 100%"
        :src="activePreview"
        alt=""
      />
    </a-modal>
  </div>
</template>

<script>
import store from '@/store';
import { imageUrl, uploadFile } from '@/helpers';

export default {
  name: 'InputUploader',
  props: {
    value: {
      type: String,
      default: null,
    },
    accept: {
      type: String,
      default: 'image/*',
    },
    config: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    // always on in single uploader, real value in multi
    totalLength: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      activePreview: null,
    };
  },
  computed: {
    width() {
      return (!this.readOnly && this.config?.width) || 104;
    },
    height() {
      return (!this.readOnly && this.config?.height) || 104;
    },
    uploaderStyle() {
      return `height: ${this.height - 2}px;`;
    },
    wrapperStyle() {
      return `width: ${this.width}px;`;
    },
    isEmpty() {
      return !this.fileList?.length;
    },
    fileList: {
      get() {
        if (this.value) {
          const sizeMultiplier =
            store.state.display.isRetina || store.state.display.isHighDensity ? 2 : 1;
          return [
            {
              uid: this.value,
              name: this.value.replace(/^.*\//g, ''),
              url: imageUrl(this.value, {
                width: this.width * sizeMultiplier,
                height: this.height * sizeMultiplier,
              }),
              urlFull: imageUrl(this.value),
            },
          ];
        }

        return [];
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    beforeUpload() {
      return false;
    },
    imageClick(event) {
      if (this.disabled || this.readOnly) {
        event.stopPropagation();
        this.showPreview();
      }
    },
    showPreview() {
      this.activePreview = this.fileList[0]?.urlFull;
    },
    closePreview() {
      this.activePreview = null;
    },
    remove() {
      this.handleChange({ fileList: [] });
    },
    async handleChange({ fileList }) {
      if (fileList.length > 0) {
        try {
          this.fileList = await uploadFile(fileList[0].originFileObj);
        } catch (e) {
          this.emitError(this.$t('app.error.uploading'), e.message);
        }
      } else {
        this.$emit('remove', this.fileList[0].uid);
        this.fileList = null;
      }
    },
  },
};
</script>

<style lang="scss">
.uploader {
  max-width: 100%;

  &:not(.empty) .ant-upload-select-picture-card {
    border: none;
    background: none;
  }

  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 100%;
    margin-bottom: 0;

    .ant-upload {
      padding: 0;
      display: flex;
    }
  }

  .ant-upload-picture-card-wrapper {
    display: block;
    height: 100%;
  }

  &__load {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
  }
}

.uploader-image {
  position: relative;

  &__overlay {
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.25);
  }

  &:not(.disabled):hover &__overlay {
    display: flex;
  }

  &__control.anticon {
    font-size: 18px;
    color: #eee;

    & + & {
      margin-left: 16px;
    }

    &:hover {
      opacity: 0.75;
    }
  }

  img {
    max-width: 100%;
    min-height: 100%;
  }
}
</style>
