<template>
  <div
    class="entity-modal publishable"
    :class="{ hasHistory: !subformMeta && history && history.length > 0 }"
  >
    <div
      v-show="loading > 0"
      class="publishable__loader"
    ></div>

    <entity-view
      v-if="contentVisible && savedData"
      :id="id"
      :type="entityName"
      :dataProcessor="dataProcessor"
      :data="savedData"
      isModal
      noHistory
      isNewMeta
      :extMeta="formMeta.fields"
      @goBack="close"
    >
      <template
        #form1
        v-if="!subformMeta && history"
      >
        <publishable-entity-history
          :history="history"
          :selectedVersion="selectedVersion"
          :currentVersion="currentVersion"
          :published="published"
          @selectVersion="selectVersion"
        />
      </template>

      <template #form2>
        <div v-if="subformMeta">
          <PublishableEntityForm
            :formName="subformName"
            :formMeta="subformMeta"
            :formData="subformCtx"
            :formState="formState"
            @buttonClick="onSubformButtonClick"
          />
        </div>

        <div v-else-if="!formConfig || !savedData || !formMeta"></div>

        <PublishableEntityForm
          v-else
          :formName="formName"
          :formMeta="formMeta"
          :formData="savedData"
          :formState="formState"
          @buttonClick="onButtonClick"
          @dataChanged="currentDataChanged"
        />
      </template>
    </entity-view>

    <div class="publishable__footer"></div>
  </div>
</template>

<script>
import { isEqual } from 'lodash';
import store from '@/store';
import {
  XHR,
  bus,
  parseJson,
  deepFind,
  composeGqlQueryFromAction,
  composeGqlQueryFromActionWithVariables,
} from '@/helpers';
import FormConfigService from '@/services/FormConfigService';
import EntityView from '@/components/edit-form/EntityView';
import PublishableEntityHistory from './PublishableEntityHistory';
import PublishableEntityForm from './PublishableEntityForm';

/**
 * sourceData - получаемые текущие данные сущности с бека, прелетающие из get-экшена
 *   изменяется только по данным из ответов экшенов
 *
 * savedData - sourceData + данные выбранной версии в хистори. чистый набор данных,
 *   с котором сравнивается текущий (data) для определяния наличия изменений в форме
 *   изменяется после экшенов и при выборе версии
 *
 * data - текущие данные формы. savedData + любые изменения полей
 */

export default {
  components: {
    EntityView,
    PublishableEntityHistory,
    PublishableEntityForm,
  },

  apollo: {
    $client: 'api2client',
  },

  props: {
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    fullscreen: {
      type: Boolean,
      required: true,
    },
    dataProcessor: {
      type: Function,
      default: null,
    },
    contentVisible: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      loading: 0,
      formMeta: null,
      formConfig: null,
      data: null,
      sourceData: null,
      savedData: null,
      history: null,
      currentVersion: null,
      selectedVersion: null,
      selectedVersionEntry: null,

      subformName: null,
      subformMeta: null,
      subformCtx: null,
    };
  },

  computed: {
    isDraft() {
      return this.id.startsWith('_temp_');
    },

    entityName() {
      return store.state.activeSidebarItem.code;
    },

    formName() {
      return store.state.activeSidebarItem.id;
    },

    formMetaSource() {
      const metaField = this.isDraft ? 'createMeta' : 'updateMeta';
      return store.state.activeSidebarItem.customprops?.[metaField];
    },

    version() {
      return this.selectedVersion;
    },

    published() {
      const fieldMeta = this.fieldsMeta.find((field) => field.name === 'j_published');
      if (fieldMeta?.renderer === 'hidden') return !!parseJson(this.data.j_published);
      return !!this.data.j_published;
    },

    historyAction() {
      return this.formMeta.fields.find((f) => f.name === 'history').action;
    },

    fieldsMeta() {
      return this.formMeta.fields.filter((f) => !['history', 'button'].includes(f.renderer));
    },

    formState() {
      const isDirty = !isEqual(this.savedData, this.data);
      let isPublished = !!this.data.j_published;
      if (this.fieldsMeta.find((field) => field.name === 'j_published')?.renderer === 'hidden') {
        isPublished = !!parseJson(this.data.j_published);
      }

      return {
        isDirty,
        isPublished,
        isCurrentVersion: this.selectedVersion === this.currentVersion,
        isNeedsReview: !!this.selectedVersionEntry?.j_needs_review,
        handbooks: this.formMeta.handbooks,
      };
    },
  },

  async created() {
    this.loading++;
    await this.loadMeta();
    await this.loadData();
    await this.loadHistory();

    if (!this.isDraft) {
      this.selectVersion(this.history.find((entry) => entry.j_version === this.currentVersion));
    }

    this.loadConfig();
    this.loading--;
  },

  methods: {
    editEntity(...args) {
      this.$emit('editEntity', ...args);
    },

    close() {
      this.$emit('close');
    },

    async loadData() {
      if (this.isDraft) {
        this.resetData();
        return;
      }

      let sourcesKeys = this.formMeta.fields.map(
        (field) => field.source?.match(/^\$([a-zA-Z_]+?)\./)[1] || 'ctx',
      );
      sourcesKeys = [...new Set(sourcesKeys)].filter((key) => key !== 'ctx');

      const loadedData = await Promise.all(
        sourcesKeys.map((sourceKey) => this.loadDataSource(this.formMeta[sourceKey])),
      );

      const combinedData = loadedData.reduce((acc, data) => {
        Object.assign(acc, data);
        return acc;
      }, {});

      const data = this.formMeta.fields.reduce((acc, field) => {
        if (field.source) {
          const path = field.source.match(/^\$(?:[a-zA-Z_]+?)\.(.*)$/)[1];

          const value = deepFind(combinedData, path);
          if (field.renderer === 'hidden') {
            acc[field.name] = JSON.stringify(value);
          } else {
            acc[field.name] = value;
          }
        }

        return acc;
      }, {});

      this.resetData(data);
    },

    async loadDataSource(action) {
      if (action.type === 'REST') {
        const data = await this.loadRestData(action);
        return data;
      }

      if (action.type === 'GRAPHQL') {
        const { data } = await this.$apollo.query({
          query: composeGqlQueryFromAction(action, {}, this, this.fieldsMeta),
          client: 'api2client',
          fetchPolicy: 'network-only',
        });

        return data[this.entityName].get;
      }
    },

    async loadRestData(action) {
      return new Promise((resolve, reject) => {
        this.loadingQueries++;
        let url = action.url;

        if (action.params?.length) {
          if (action.paramsType === 'path') {
            url = action.params.reduce(
              (acc, name) => `${acc}/${this.$route.params.rowData[name]}`,
              url,
            );
          }
        }

        XHR.query(action.method, url, {
          absoluteUrl: true,
        }).then(
          (data) => {
            data = parseJson(data);

            this.loadingQueries--;
            resolve(data);
          },
          (error) => {
            this.$notification.error({
              message: error.message,
            });

            this.loadingQueries--;
            reject();
          },
        );
      });
    },

    async loadMeta() {
      const response = await XHR.get(this.formMetaSource);

      const meta = parseJson(parseJson(response));
      meta.fields = meta.fields.map((field) => {
        field.types = field.conf?.types;

        if (['component', 'embed'].includes(field.renderer)) {
          const fieldIsEmbed = field.renderer === 'embed';
          const childDict = fieldIsEmbed ? 'embeds' : 'components';

          field.typesDict = field.types.reduce((dict, type) => {
            dict[type] = store.state.meta[childDict][type];
            return dict;
          }, {});
        }

        if (field.name === 'j_comments') {
          field.name = 'h_j_comments';
          field.source = '$data.h_j_comments';
        }

        return field;
      });
      this.formMeta = meta;
    },

    loadConfig() {
      this.formConfig = FormConfigService.getMetaFormConfig(store.state.activeSidebarItem.id, {
        ...this.formMeta,
        fields: this.formMeta.fields.filter((f) => !['history'].includes(f.renderer)),
      });
    },

    async loadHistory(selectVersion) {
      this.loading++;
      const historyField = this.formMeta.fields.find((f) => f.renderer === 'history');

      if (this.isDraft || !historyField) {
        this.loading--;
        return;
      }

      const { data } = await this.$apollo.query({
        client: 'api2client',
        fetchPolicy: 'network-only',
        query: composeGqlQueryFromAction(
          this.historyAction,
          this.sourceData,
          this,
          this.fieldsMeta,
        ),
      });

      this.history = data[this.entityName].history.data.sort(
        (a, b) => b.j_created_at - a.j_created_at,
      );

      if (selectVersion) {
        this.selectVersion(this.history.find((entry) => entry.j_version === selectVersion));
      }

      this.loading--;
    },

    onButtonClick(data, { action }) {
      Object.assign(this.data, data);
      this.performAction(action, data, this, this.fieldsMeta);
    },

    performAction(action, data, context, fieldsMeta, onSuccess = this.onActionSuccess) {
      if (action.type === 'GRAPHQL') {
        const query = composeGqlQueryFromActionWithVariables(
          action,
          data,
          context,
          this.fieldsMeta,
        );

        this.loading++;
        this.$apollo
          .mutate({
            mutation: query.query,
            variables: query.variables,
          })
          .then(
            (response) => {
              const path = action.path.split('.').slice(1).join('.');
              const newId = deepFind(response.data, `${path}.id`, true);

              const responseDataKey = action.path.split('.').slice(-1)[0];
              const responseData = response.data[this.entityName][responseDataKey];

              onSuccess(action, newId, responseData, response);
            },
            (r) => {
              console.log('error', r);
            },
          );
      } else if (action.type === 'REST') {
        let url = action.url;
        this.loading++;

        if (action.params?.length) {
          if (action.paramsType === 'path') {
            url = action.params.reduce(
              (acc, name) => `${acc}/${this.$route.params.rowData[name]}`,
              url,
            );
          }
        }

        XHR.query(action.method, url, {
          absoluteUrl: true,
          data:
            action.params &&
            action.params.reduce((acc, key) => {
              acc[key] = data[key];
              return acc;
            }, {}),
        }).then(
          (response) => {
            onSuccess(action, null, parseJson(response));
          },
          (error) => {
            this.$notification.error({
              message: error.message,
            });

            this.loading--;
          },
        );
      }
    },

    async onActionSuccess(action, newId, responseData, response) {
      bus.$emit('refetchTable');

      if (response?.extensions) {
        let url = response.extensions.form;
        url = url.startsWith('/forms/') ? url : `/forms/${url}`;

        const extData = await XHR.get(url);
        this.subformName = response.extensions.form;

        const meta = parseJson(extData);
        meta.fields = meta.fields.map((field) => {
          field.types = field.conf?.types;

          if (['component', 'embed'].includes(field.renderer)) {
            const fieldIsEmbed = field.renderer === 'embed';
            const childDict = fieldIsEmbed ? 'embeds' : 'components';

            field.typesDict = field.types.reduce((dict, type) => {
              dict[type] = store.state.meta[childDict][type];
              return dict;
            }, {});
          }

          return field;
        });
        this.subformMeta = meta;

        this.subformCtx = response.extensions.ctx;

        this.loading--;
        return;
      }

      this.$notification.success({
        message: this.$t('metaform.dataSaved'),
      });

      if (action.closeForm) {
        return this.close();
      }

      if (newId && newId !== this.id) {
        return this.$emit('editEntity', this.type, newId, false, true);
      }

      if (this.subformMeta) {
        this.closeSubform();
      }

      const version = this.selectedVersion;
      this.selectVersion();
      this.applyData(responseData);
      this.loadHistory(version);
      this.loading--;
    },

    resetData(data = {}, { updateSource = true } = {}) {
      this.data = { ...data };
      this.savedData = { ...data };

      if (updateSource) {
        this.sourceData = { ...data };
      }

      if (this.sourceData.j_version) {
        try {
          this.currentVersion = parseJson(this.sourceData.j_version);
        } catch {
          this.currentVersion = this.sourceData.j_version;
        }
      }
    },

    applyData(updatedData) {
      if (!updatedData) return;

      updatedData = this.fieldsMeta.reduce(
        (acc, field) => {
          if (updatedData[field.name] !== undefined) {
            const formField = this.formMeta.fields.find((f) => f.name === field.name);
            acc[field.name] =
              formField.renderer === 'hidden'
                ? JSON.stringify(updatedData[field.name])
                : updatedData[field.name];
          }

          return acc;
        },
        { ...this.data },
      );

      this.resetData(updatedData);
    },

    applyHistoryData(historyData) {
      if (!historyData) return;

      const newFieldsMeta = [];
      const updateData = { ...this.data };

      Object.keys(historyData).forEach((key) => {
        if (key === '__typename') return;

        const dataKey = key.startsWith('j_') ? `h_${key}` : key;
        const formField = this.formMeta.fields.find((field) => field.name === dataKey);

        if (formField) {
          updateData[dataKey] =
            formField.renderer === 'hidden' ? JSON.stringify(historyData[key]) : historyData[key];
        } else {
          newFieldsMeta.push({
            name: dataKey,
            source: `$_.${dataKey}`,
            renderer: 'hidden',
            sortable: true,
          });

          updateData[dataKey] = JSON.stringify(historyData[key]);
        }
      });

      this.formMeta.fields.push(...newFieldsMeta);
      this.resetData(updateData, { updateSource: false });
    },

    selectVersion(historyEntry) {
      if (historyEntry) {
        this.applyHistoryData(historyEntry);
        this.selectedVersion = historyEntry.j_version;
        this.selectedVersionEntry = historyEntry;
      } else {
        this.selectedVersion = null;
        this.selectedVersionEntry = null;
      }
    },

    currentDataChanged(data) {
      this.data = { ...this.data, ...data };
    },

    closeSubform() {
      this.subformName = null;
      this.subformMeta = null;
      this.subformCtx = null;
    },

    onSubformButtonClick(data, { action, conf }) {
      if (conf?.type === 'cancel') {
        return this.closeSubform();
      }

      this.performAction(
        action,
        data,
        this.subformCtx,
        this.subformMeta,
        this.onSubformActionSuccess,
      );
    },

    async onSubformActionSuccess(action, newId, responseData) {
      bus.$emit('refetchTable');

      this.$notification.success({
        message: this.$t('metaform.dataSaved'),
      });

      if (action.closeForm) {
        return this.close();
      }

      this.closeSubform();

      const version = this.selectedVersion;
      this.selectVersion();
      this.applyData(responseData);
      this.loadHistory(version);
      this.loading--;
    },
  },
};
</script>

<style lang="scss">
.publishable {
  position: relative;

  .entity-view {
    padding-bottom: 0;
    min-height: calc(100% - 40px);
  }

  &.hasHistory .entity-view__forms {
    display: flex;
    flex-grow: 1;

    & > div:nth-child(1) {
      flex: 0 0 25%;
      padding-right: 20px;
    }

    & > div:nth-child(2) {
      flex: 1;
      min-width: 0;
    }
  }

  .entity-view__head-button {
    display: none;
  }

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #fff;
    opacity: 0.5;
    z-index: 30;
  }

  &__footer {
    position: sticky;
    z-index: 1;
    right: 0;
    bottom: 0;
    left: 0;
    background: #f9f9f9;
    height: 40px;
  }

  .json-compare,
  .json-compare > .editor {
    height: 100%;
  }

  .field-renderer--button {
    width: auto;
    margin-bottom: 30px;
  }

  .field-renderer--hidden {
    height: 0;
    overflow: hidden;
  }
}
</style>
