<!--
Временная заглушка для задачи с выводом формы для конфига SYSTEM,
использовать при переводе всей админки но новую мету
-->

<template>
  <div class="meta-form">
    <div class="page-block__title">
      <h1 class="ellipsis">{{ title }}</h1>
    </div>

    <form-layout-provider
      v-if="!loading"
      #default="{ formLayout }"
      :formConfig="formConfig"
      :fieldsMeta="meta.fields"
    >
      <entity-form
        :id="'TEST'"
        :type="'META_FORM_TEST'"
        :formLayout="formLayout"
        :sourceData="data"
        :formConfig="formConfig"
        :descriptors="meta.fields"
        :canReadModel="true"
        @buttonClick="onButtonClick"
      >
        <template #bottomControls>
          <!-- <pre>
            {{ formLayout }}
          </pre> -->
        </template>
      </entity-form>
    </form-layout-provider>
  </div>
</template>

<script>
import store from '@/store';
import { XHR, parseJson } from '@/helpers';
import FormConfigService from '@/services/FormConfigService';
import EntityForm from '@/components/edit-form/EntityForm';
import FormLayoutProvider from '@/components/edit-form/FormLayoutProvider';

export default {
  name: 'MetaForm',

  components: { EntityForm, FormLayoutProvider },

  data() {
    return {
      loading: 0,
      meta: null,
      formConfig: null,
      data: {},
    };
  },

  computed: {
    pageProps() {
      return store.state.activeSidebarItem.customprops;
    },

    type() {
      return this.pageProps.metaSource.type;
    },

    title() {
      return store.state.activeSidebarItem.title;
    },
  },

  watch: {
    pageProps() {
      this.initialize();
    },
  },

  async created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.loading++;
      this.loadData();
      await this.loadMeta();
      this.loadConfig();
      this.loading--;
    },

    async loadMeta() {
      const response = await XHR.get(this.pageProps.metaSource);
      this.meta = parseJson(response);
    },

    async loadConfig() {
      this.formConfig = FormConfigService.getMetaFormConfig(
        store.state.activeSidebarItem.id,
        this.meta,
      );
    },

    async loadData() {
      const response = await XHR.get(this.pageProps.dataSource);
      this.data = parseJson(response);
    },

    onButtonClick(data, { action }) {
      if (action.type === 'REST') {
        this.loading++;

        Object.assign(this.data, data);
        XHR.query(action.method, action.url, {
          absoluteUrl: true,
          data:
            action.params &&
            action.params.reduce((acc, key) => {
              acc[key] = data[key];
              return acc;
            }, {}),
        }).then(
          (response) => {
            this.$notification.success({
              message: this.$t('metaform.dataSaved'),
            });

            const responseData = parseJson(response) || {};
            this.data = {
              ...this.data,
              ...responseData,
            };
            this.loading--;
          },
          (error) => {
            this.$notification.error({
              message: error.message,
            });

            this.loading--;
          },
        );
      }
    },
  },
};
</script>

<style lang="scss">
.meta-form {
  .field-renderer--button {
    width: auto;
    margin-bottom: 30px;
  }
}
</style>
