import gql from 'graphql-tag';
import { parseJson } from './json';
import { GqlObject } from './GqlObject';

/**
 * Для запроса /meta
 */
class MetaQueryParser {
  prepareMeta(meta, user) {
    Object.values(meta.components).forEach((component) =>
      this.prepareMetaObject(component, meta, user),
    );

    Object.values(meta.embeds).forEach((embed) => this.prepareMetaObject(embed, meta, user));
    Object.values(meta.processes).forEach((process) => this.prepareMetaObject(process, meta, user));
    return meta;
  }

  prepareMetaObject(object, meta, user) {
    object.fields.forEach((field) => {
      if (!['component', 'embed'].includes(field.renderer)) return;

      const fieldIsEmbed = field.renderer === 'embed';
      const childDict = fieldIsEmbed ? 'embeds' : 'components';

      field.typesDict = field.types.reduce((dict, type) => {
        dict[type] = meta[childDict][type];
        return dict;
      }, {});
    });

    this.updateObjectOperations(object, user);
    return object;
  }

  updateObjectOperations(object, user) {
    object.operations = ['TABLE', 'READ', 'UPDATE', 'CREATE', 'DELETE'].reduce((list, opName) => {
      const opRule = object.securityRules.find((rule) => rule.operation === opName);
      const roles = opRule?.roles || [];
      list[opName] = !roles.length || roles.some((role) => user.roles.includes(role));
      return list;
    }, {});
  }

  updateOperations(meta, user) {
    Object.values(meta.components).forEach((comp) => this.updateObjectOperations(comp, user));
    Object.values(meta.embeds).forEach((embed) => this.updateObjectOperations(embed, user));
    Object.values(meta.processes).forEach((process) => this.prepareMetaObject(process, meta, user));
  }
}

export const metaQueryParser = new MetaQueryParser();

function getFieldValueForGraph(
  value,
  fieldsMeta = null,
  fieldName = null,
  gqlObject = false,
  mapper = null,
  retString = false,
) {
  const fieldMeta = fieldsMeta?.find((field) => field.name === fieldName);
  if (fieldMeta?.renderer === 'embed' && retString) {
    if (Array.isArray(value)) return value.map((e) => e.data);
  }

  if (fieldMeta?.renderer === 'boolean') {
    value = value ?? false;
  }

  if (value === undefined) return value;

  if (fieldMeta?.renderer === 'hidden' || fieldMeta?.renderer === 'json') {
    value = parseJson(value);
    if (value === undefined) return value;
    if (retString && fieldMeta?.renderer === 'json') return parseJson(value) || null;
    if (retString && typeof value === 'string') return value;
  }

  if (fieldMeta?.renderer === 'string') {
    value = value || '';
    if (retString) return value;
  } else if (retString && !fieldMeta && typeof value === 'string') {
    return value;
  }

  if (fieldMeta?.conf?.handbooks) {
    return fieldMeta.multiple ? `[${value.join(', ')}]` : value;
  }

  if (value instanceof GqlObject) {
    mapper = value.mapper;
    value = value.value;
    gqlObject = true;
  }

  if (gqlObject) {
    if (Array.isArray(value)) {
      const list = value
        .map((item) => getFieldValueForGraph(item, null, null, true, mapper))
        .filter((v) => v !== undefined);
      return `[${list.join(', ')}]`;
    }

    if (value && typeof value === 'object') {
      if (mapper) {
        return mapper(value);
      }

      const list = Object.keys(value).reduce((acc, key) => {
        const itemValue = getFieldValueForGraph(value[key], fieldsMeta, null, null, true);
        if (itemValue !== undefined) {
          acc.push(`${key}: ${itemValue}`);
        }

        return acc;
      }, []);

      return `{${list.join(', ')}}`;
    }

    if (value === undefined) return value;
    return JSON.stringify(value);
  }

  if (value && typeof value === 'object' && !Array.isArray(value)) {
    const list = Object.keys(value).reduce((acc, key) => {
      const itemValue = getFieldValueForGraph(value[key], fieldsMeta);
      if (itemValue !== undefined) {
        acc.push(`${key}: ${itemValue}`);
      }
      return acc;
    }, []);

    return `{${list.join(', ')}}`;
  }

  return JSON.stringify(value);
}

function composeGqlQueryParameter(dataKey, dataSource, formData, context, fieldsMeta) {
  const isArray = Array.isArray(dataSource);
  const isObject = !isArray && typeof dataSource === 'object';
  const isConstant =
    !isArray && !isObject && (typeof dataSource !== 'string' || !dataSource.startsWith('$'));
  const isContext = !isArray && !isObject && !isConstant && dataSource.startsWith('$ctx');
  let value;

  if (isObject) {
    const subfieldsValues = Object.entries(dataSource)
      .map(([subDataKey, subDataSource]) =>
        composeGqlQueryParameter(subDataKey, subDataSource, formData, context, fieldsMeta),
      )
      .filter((v) => v !== undefined);

    value = `{${subfieldsValues.join(', ')}}`;
  } else if (isConstant) {
    value = getFieldValueForGraph(dataSource);
  } else if (isContext) {
    value = getFieldValueForGraph(context[dataSource.slice(5)]);
  } else if (isArray) {
    value = `[${dataSource.join(', ')}]`;
  } else {
    value = getFieldValueForGraph(formData[dataSource.slice(1)], fieldsMeta, dataSource.slice(1));
  }

  if (value === undefined) return value;
  return `${dataKey}: ${value}`;
}

function composeGqlQueryParametersList(action, data, context, fieldsMeta) {
  return Object.entries(action.parameters)
    .map(([dataKey, dataSource]) =>
      composeGqlQueryParameter(dataKey, dataSource, data, context, fieldsMeta),
    )
    .filter((v) => v !== undefined)
    .join(', ');
}

function composeRequestVar(action, dataKey, dataSource, formData, context, fieldsMeta, depth = 0) {
  const isObjectValue = typeof dataSource === 'object';
  // const isConstant =
  //   !isObjectValue && (typeof dataSource !== 'string' || !dataSource.startsWith('$'));
  // const isContextField = !isObjectValue && !isConstant && dataSource.startsWith('$ctx');
  let value;

  const splitPath = action.path.split('.');
  if (
    depth === 0 &&
    dataKey === 'data' &&
    isObjectValue &&
    ['create', 'createWithAutogenLinkedId', 'update'].includes(splitPath[2])
  ) {
    value = Object.keys(dataSource).reduce((acc, key) => {
      acc[key] = getFieldValueForGraph(
        formData[dataSource[key].slice(1)],
        fieldsMeta,
        dataSource[key].slice(1),
        false,
        null,
        true,
      );
      return acc;
    }, {});

    // const valueinparam = getFieldValueForGraph(value, [{ data: { renderer: 'json' } }], 'data');
    const valueinparam = composeGqlQueryParameter(
      dataKey,
      dataSource,
      formData,
      context,
      fieldsMeta,
      (depth = 0),
    );

    // value = formData[dataSource.slice(1)];
    // return [`$data: Json`, 'data', value, valueinparam];
    return [`$data: ${splitPath[1]}LogIn`, 'data', value, valueinparam];
  }

  // if (!isObjectValue && !isConstant && !isContextField) {
  //   const splitPath = action.path.split('.');
  //   if (
  //     dataKey === 'data' &&
  //     depth === 0 &&
  //     ['create', 'createWithAutogenLinkedId', 'update'].includes(splitPath[2])
  //   ) {
  //     const valueinparam = getFieldValueForGraph(
  //       formData[dataSource.slice(1)],
  //       fieldsMeta,
  //       dataSource.slice(1),
  //     );

  //     value = formData[dataSource.slice(1)];
  //     return [`$data: Json`, 'data', value, valueinparam];
  //   }
  // }

  // else if (isConstant) {
  //   value = getFieldValueForGraph(dataSource);
  // } else if (isContextField) {
  //   value = getFieldValueForGraph(context[dataSource.slice(5)]);
  // } else {
  //   value = getFieldValueForGraph(formData[dataSource.slice(1)], fieldsMeta, dataSource.slice(1));
  // }

  if (value === undefined) return value;
  return `${dataKey}: ${value}`;
}

function composeRequestVarsList(action, data, context, fieldsMeta) {
  return Object.entries(action.parameters)
    .map(([dataKey, dataSource]) =>
      composeRequestVar(action, dataKey, dataSource, data, context, fieldsMeta),
    )
    .filter((v) => v !== undefined);
}

export function composeGqlQueryFromAction(action, data, context, fieldsMeta) {
  const pathTokens = action.path.split('.').reverse();
  const query = pathTokens.reduce((acc, slug, index) => {
    if (index === 0) {
      const params = composeGqlQueryParametersList(action, data, context, fieldsMeta);
      if (params) slug = `${slug}(${params})`;
    }

    if (acc) return `${slug} {${acc}}`;
    return slug;
  }, action.responseVars);

  return gql`
    ${query}
  `;
}

export function composeGqlQueryFromActionWithVariables(action, data, context, fieldsMeta) {
  const requestVars = composeRequestVarsList(action, data, context, fieldsMeta);
  const pathTokens = action.path.split('.').reverse();

  let query = pathTokens.reduce((acc, slug, index) => {
    if (index === 0) {
      const params = composeGqlQueryParametersList(action, data, context, fieldsMeta);
      if (params) slug = `${slug}(${params})`;
    }

    if (index === pathTokens.length - 1 && requestVars.length) {
      slug += ` QueryWithVars(${requestVars.map((rv) => rv[0]).join(', ')})`;
    }

    if (acc) return `${slug} {${acc}}`;
    return slug;
  }, action.responseVars);

  if (requestVars) {
    const variables = requestVars.reduce((acc, rv) => {
      acc[rv[1]] = parseJson(rv[2]);
      return acc;
    }, {});

    requestVars.forEach((rv) => {
      query = query.replace(rv[3], `${rv[1]}: $${rv[1]}`);
    });

    return {
      query: gql`
        ${query}
      `,
      variables,
    };
  }

  return gql`
    ${query}
  `;
}
